import MuiCheckbox from '@material-ui/core/Checkbox';
import { SupportedLanguage, getSupportedLanguages } from 'app/constants/supportedLanguages';
import { useField } from 'formik';
import { debounce } from 'lodash';
import React, { ReactElement, ReactNode } from 'react';

export class UtilsService {
  /**
   * Runs through each of `propNames` on object and assigns an empty object if `undefined`.
   * @param obj - any object
   * @param propNames - array of property names in order
   */
  static fillWithEmpty(obj: any, propNames: string[]): void {
    const prop = propNames[0];
    if (prop && obj[prop] === undefined) {
      obj[prop] = {};
    }
    propNames.shift();
    if (propNames.length) {
      this.fillWithEmpty(obj[prop], propNames);
    }
  }
  
  /**
   * Helper to avoid synthetic event null issue on react handlers when using debounce.
   * Warning: `currentTarget` may be null and target's value (if input) may not update correctly.
   * REF: https://stackoverflow.com/questions/35435074/using-debouncer-with-react-event
   * @param fn 
   * @param time 
   */
  static debounceEventHandler(fn: (e: any) => void, time: number) {
    const debounced = debounce(fn, time);
    return (evt: any) => {
      evt.persist();
      return debounced(evt);
    }
  }

  /**
   * Renders a ReactNode based on a truthy condition. Else can be optionally provided either as a ReactNode or a function returning a ReactNode.
   * If you are using `elseContent` it is recommended that you wrap you're ReactNodes in functions to avoid them getting executed even when conditions are not met.
   * For example, if `elseContent` is provided, and you have functions within it that will error without some specific data, you should wrap the ReactNode in a function,
   * so it only gets called when the condition becomes falsy. Otherwise you'll get errors even though the condition is still true, as both ReactNodes will get executed, 
   * even though they are not both rendered.
   * @param condition 
   * @param content 
   * @param elseContent 
   */
  static renderIf(condition: any, content: ReactNode | (() => ReactNode), elseContent: ReactNode | (() => ReactNode) = null): ReactNode {
    return condition ? (
      typeof content === 'function' ? content() : content
    ) : (
      typeof elseContent === 'function' ? elseContent() : elseContent
    );
  }
  

  // removes any falsey values (except false)
  static omitFalseyProps(data: any, omitFalse = false): any {
    return Object.keys(data).reduce((a: any, key: string) => {
      const val: any = data[key];
      if (val !== undefined && 
          val !== null && 
          val !== '' &&
          (omitFalse ? val !== false : true)
        ) {
        a[key] = val;
      }
      return a;
    }, {});
  }

  // workaround for initial checked state on load (https://github.com/mui-org/material-ui/issues/16434)
  static getMuiFormikCheckBox({ ...props }) {
    const [field] = useField(props.name);
    return <MuiCheckbox {...field} checked={field.value} />;
  }

  /**
   * Make links open in a new window when using ReactMarkdown
   * Use like this:
   * <ReactMarkdown source={text} 
      renderers={{
        paragraph: 'span',
        link: UtilsService.reactMarkDownBlankTargets
      }} 
      escapeHtml={false} />
   */
  static reactMarkDownBlankTargets(props:{href: string, children: string}): ReactElement {
    return (<a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>);
  }

  /**
   * Injects target="_blank" into free text. May not be used anymore, in favour of `reactMarkDownBlankTargets`
   */
  static injectBlankTargetsIntoFreeText(text: string): string {
    const A_START = '<a';
    const A_END = '</a>';
    const splitAnchorStart: string[] = text.split(A_START);
    if (splitAnchorStart.length > 1) {
      const newText = splitAnchorStart.map((chunk, i) => {
        if (i === 0) {
          return chunk;
        }
        const aChunks: string[] = chunk.split(A_END);
        return `${A_START} target="_blank"${aChunks[0]}${A_END}${aChunks[1]}`;
      }).join('');
      return newText;
    }

    return text;
  }

  static rangeCheck(val: number, max: number, min: number): number {
    if (val < min) {
      val = min;
    } else if(val > max) {
      val = max;
    }
    return val;
  }

  // https://css-tricks.com/converting-color-spaces-in-javascript/#article-header-id-9
  static hexToHSL(H: string): number[] {
    // Convert hex to RGB first
    let r = 0;
    let g = 0;
    let b = 0;
    if (H.length == 4) {
      r = ("0x" + H[1] + H[1]) as unknown as number;
      g = ("0x" + H[2] + H[2]) as unknown as number;
      b = ("0x" + H[3] + H[3]) as unknown as number;
    } else if (H.length == 7) {
      r = ("0x" + H[1] + H[2]) as unknown as number;
      g = ("0x" + H[3] + H[4]) as unknown as number;
      b = ("0x" + H[5] + H[6]) as unknown as number;
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin;
    let h = 0,
        s = 0,
        l = 0;

    if (delta == 0)
      h = 0;
    else if (cmax == r)
      h = ((g - b) / delta) % 6;
    else if (cmax == g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
      h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [h,s,l];
    // return "hsl(" + h + "," + s + "%," + l + "%)";
  }

  // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  static hexToRgb(hex: string): number[] {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))
  }

  // Maybe useful for future should takeaway mode be enabled on widget v2
  static getOrderType(isTakeAwayMode = false): string {
    return isTakeAwayMode ? 'order' : 'booking';
  }

  static getPhoneLinkHtml(phone: string) {
    return `<a class="underline-phone" href="tel:${phone.split(' ').join('')}">${phone}</a>`;
  }

  /* for this one getting default language is a bit tricky cause in i18 we pass the supportedLngs which is in format "lang-CA" -
     And we supportedLngs everywhere - so changing that would be a big test. So getting the language and adding -CA to the language from the browser and then passing that to i18
  */
  static getDefaultLanguageFromBrowser(language: string): string {
    const defaultLanguage = language.split('-')[0];
    const langChoice = `${defaultLanguage}-CA`;
    if (!getSupportedLanguages().includes(langChoice as SupportedLanguage)) {
      return 'en-CA';
    }
    return langChoice;
  }
}

// so you can import directly
export const renderIf = UtilsService.renderIf;
