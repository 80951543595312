import appValues from 'app/constants/appValues';
import { connect } from 'react-redux';
import MainHeader from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { ImageService } from 'app/services/image/image.service';
import { loadStatus } from 'app/types/common.types';
import {bannerType, themeTypes} from 'app/models';

const NS = 'MainHeaderContainer';

const mapStateToProps = ({widget, setup}: IRootState): IStateFromProps => {
  const { theme, booking, wrapperStyle, sessionRemainingTime, accountDetails, appSettings, venues } = widget;
  const {isStyleGuide} = appSettings;
  const businessName = accountDetails ? accountDetails.businessName : '(Venue name)';
  const venue = venues ? venues.venuesList.find(v => v.id === venues.selectedVenueId) : null;

  // using `appSettings.theme` because `props.theme` has not initialized yet
  const isDark = widget.appSettings.theme === themeTypes.dark || widget.appSettings.theme === themeTypes.outlinedDark;

  return {
    appVersion: appValues.VERSION,
    venue,
    theme,
    booking,
    viewTime: booking && booking.viewTime ? (booking.viewTime) : null,
    businessName,
    wrapperStyle,
    showAppVersion: !appValues.Environment.IsProd,
    logoUrl: ImageService.getLogoUrl(venue, wrapperStyle, accountDetails),
    isLoading: setup.appLoadStatus === loadStatus.loading || setup.appLoadStatus === loadStatus.failed,
    isStyleGuide,
    // placeholder time added to style guide for testing UI
    sessionRemainingTime: isStyleGuide ? '8:20' : sessionRemainingTime,
    isDarkAndLoading: isDark && setup.appLoadStatus === loadStatus.loading,
    hideHeader: appSettings.banner === bannerType.hidden
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    // returnToSource: () => {
    //   // dispatch(WidgetActionsNS.xxx());
    // }
  }
};

const MainHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainHeader as any);

export default MainHeaderContainer;
