import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./fr.json";
import en from "./en.json";
import { getSupportedLanguages } from "app/constants/supportedLanguages";
import moment from "moment";
import {UtilsService} from "app/services/utils/utils.service";

const lng = UtilsService.getDefaultLanguageFromBrowser(window.navigator.language);

i18n.use(initReactI18next).init({
  resources: {
    ["fr-CA"]: fr,
    ["en-CA"]: en,
    ["en-AU"]: en,
    ["en-NZ"]: en,
  },
  supportedLngs: getSupportedLanguages(),
  lng,
  fallbackLng: "en-AU",
  interpolation: {
    // Don't escape e.g. "Afternoon / après-midi" to "Afternoon &#x7F; après-midi".
    escapeValue: false
  }
});

const momentLocaleOptions: { [key: string]: moment.LocaleSpecification } =
  getSupportedLanguages().reduce(
    (prev, l) => ({
      ...prev,
      [l]: {
        week: { dow: ["en-US", "en-CA", "fr-CA"].includes(l) ? 0 : 1, doy: 1 },
      },
    }),
    {}
  );

/**
 * Configure dependencies such as {@link moment} with additional locale setting
 * overrides.
 * @param lng Language to set.
 */
const onLanguageChanged = (lng: string) => {
  const lang = lng.split("-")[0]; // Retain only "fr" part of "fr-CA".
  moment.locale(lang);
  moment.updateLocale(lang, momentLocaleOptions[lng]);
};

i18n.on("languageChanged", onLanguageChanged);
onLanguageChanged(lng);

export default i18n;
