
export enum NgLocalStorageKey {
  isManager = 'isManager',
  accountLocked = 'accountLocked',
  account = 'account',
  venue = 'venue',
  couchUsers = 'couchUsers',
  runSheetCols = 'runSheetCols',
  showEmptyTables = 'showEmptyTables',
  allBookings = 'allBookings',
  waitList = 'waitList',
  standbyList = 'standbyList',
  NBIReleaseVersion = 'NBIReleaseVersion',
  event = 'event',
  token = 'token',

  spamBooking = 'spamBooking',
  spamBookingTime = 'spamBookingTime',
}

/**
 * need to move all NgLocalStorageKey over to LocalStorageKey, so when they are saved in local storage they use "nbi_diary_"
 * as a prefix instead of "ng" (which is an angularJS prefix.
 */
export enum LocalStorageKey {
  token = 'token',
  isLegacyLogin = 'isLegacyLogin',
  authorisationServer = 'authorisationServer',
  client = 'client',
  redirectUri = 'redirectUri',
  codeVerifier = 'codeVerifier',
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  idToken = 'idToken',
}
