import { Environment } from 'shared-types/DiaryTypes';

interface IAppConstants {
  iPosServiceApiKey: string,
  iPosServiceUrl: string
}

const local: IAppConstants = {

  iPosServiceApiKey: '8E2CVOtwIv7sY2Oel2P5kri0CfwScfn1COcXHK3SVeG979g9NaDncGS2B6ywU9DR',
  iPosServiceUrl: 'http://localhost:5300'
}

const sandbox: IAppConstants = {

  iPosServiceApiKey: 'I3etqSfXqpQVeKxsGWWjjs9NJ7Ama23WV5RE9MKEDRayyszC5CcKyHZQsUg7DxHB',
  iPosServiceUrl: 'https://ipos-sandbox-1.nowbookit.com'
}

const dev: IAppConstants = {
  iPosServiceApiKey: '5aVuZlHsTm2sda11poU72Nb9yoeM75VcBURRmseMUBpZbNwKP0uGFP7AfQoVCtxM',
  iPosServiceUrl: 'https://ipos-dev.nowbookit.com'
};

const prod: IAppConstants = {
  iPosServiceApiKey: 'U5EIw0QHlVyHld7b44pTL6dKUEljRYdvp3IirdCh6JStC2ZxxMf14BgNjWflCeth',
  iPosServiceUrl: 'https://ipos.nowbookit.com'
};

const uat: IAppConstants = {
  iPosServiceApiKey: 'lYylXC3QjtdQwADXRmXc1SFB66QZqWZslG0BH4gNPI5TaB4Eh3mfyd2Z5NdNNO5u',
  iPosServiceUrl: 'https://ipos-uat.nowbookit.com'
};

const demo: IAppConstants = {
  iPosServiceApiKey: 'qUEW8h5wQsMNUNnDZrxhGCQQRSxEKXxJqGYFRNfZAw4s3b48TcQFNKFYC2mRvH5b',
  iPosServiceUrl: 'https://ipos-demo.nowbookit.com'
  
}

const getEnvConfig = (): IAppConstants => {

  console.log('process.env.APP_ENV', Environment);

  switch (Environment.Value) {
    case 'dev':
      return dev;
    case 'sandbox':
      return sandbox;
    case 'prod':
      return prod;
    case 'uat':
      return uat;
    case 'demo':
      return demo;
    default:
      return local;
  }
};

export const envConfig: IAppConstants = getEnvConfig();
