/** Strings corresponding to locales supported by the localization code. */
export type SupportedLanguage = "en-AU" | "en-CA" | "en-NZ" | "fr-CA";

/** Languages available to the browser. */
export const getSupportedLanguages = (): SupportedLanguage[] =>
  // If available from config.js loaded separately, use that value.
  (window as any).config?.supportedLanguages || ["fr-CA", "en-CA"];

/**
 * Returns a value indicating whether only English languages are supported.
 * We use this as a simplistic filter to format "English-only" deployments as default AU / NZ.
 */
export const getSupportedEnglishOnly = (): boolean =>
  getSupportedLanguages().every((locale) => locale.startsWith("en-"));
