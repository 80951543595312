import * as Mustache from 'mustache';
import DEFAULT_MESSAGES from '../../constants/defaultMessages';
import {IAppQueryStringParams} from 'app/models';
import { IBooking } from 'app/services/booking/booking.types';
import moment, {Moment} from 'moment';
import PhoneNumberService from 'external-services/phone-number-service';
import i18next, { t } from 'i18next';

const NS = 'MessageService';

export const templateError = `<span class='error-text'>TEMPLATE ERROR</span>`;

export const STANDBY_EXHAUSTED_MSG = 'Standby list is exhausted for this day, please try another day.';

export class MessageService {

  /**
   * Gets a message with template strings replaced by data object values using Mustache
   * @param type - name of the string (see `src/app/constants/defaultMessages.ts`)
   * @param dataForTemplate - most likely a IVenue object, but can be customised depending on template. Some messages don't need extra data.
   */
  static get(type: string, dataForTemplate: any = {}): string {
    const text = this.getMessageText(type);

    if (!text) {
      return null;
    }

    return this.getMessage(text, dataForTemplate, type);
  }

  static getMessage(text: string, dataForTemplate: any, type: string, booking?: IBooking) {
    let result: any;
    const parsedVariables: any = {};
    
    this.flattenVariables(dataForTemplate, parsedVariables);
    
    // booking.utcTime is used to get booking time for timeNoLongerAvailableMessage in Admin > Diary > Widget Messages
    if(booking && booking.utcTime) {
      parsedVariables.bookingTime = moment(booking.utcTime).format('LLLL');
    }
    
    // Format phone number to international standard
    parsedVariables.phone = PhoneNumberService.formatNumber(parsedVariables.phone, parsedVariables.country, false);
    
    if (type && parsedVariables[type]) {
      text = parsedVariables[type];
    }

    // Localize to language.
    text = t(text, { ns: "errors" });

    // Wrap phone number in url
    text = this.insertPhoneLink(text);
    
    try {
      result = Mustache.render(text, parsedVariables);
    }
    catch (err) {
      console.error('Rendering crashed:', err);
      result = `${templateError} : "${text}"`;
    }

    return result;
  }

  static getMessageText(type: string, obj?: any) {
    const message = this.searchForMessageText(type, obj);
    return message 
      ? message
      : i18next.exists(type)
      ? t(type)
      : DEFAULT_MESSAGES[type as keyof typeof DEFAULT_MESSAGES];
  }

  static getLoadingMessage(queryStringParams: IAppQueryStringParams): string {
    return queryStringParams.date 
      ? t('Retrieving event details')
      : t('Retrieving venue details');
  }

  /**
   * Checks if phone number exists in text without a link and adds it automatically
   */
  private static insertPhoneLink(text: string): string {
    const replaceWith = `<a href='tel:{{phone}}'>{{phone}}</a>`;

    // first checks common possible combinations for phone numbers and does nothing if these exist
    if (text.indexOf('tel:{{phone}}') === -1 && text.indexOf('>{{phone}}</a>') === -1 &&
      text.indexOf('tel:{{ phone }}') === -1 && text.indexOf('>{{ phone }}</a>') === -1 &&
      text.indexOf('tel:{{ phone}}') === -1 && text.indexOf('>{{ phone}}</a>') === -1 &&
      text.indexOf('tel:{{phone }}') === -1 && text.indexOf('>{{phone }}</a>') === -1) {

      // then replaces all occurances of phone number with link
      return text
        .split('{{phone}}').join(replaceWith) // must come first to avoid duplication (as this exists in replacement string)
        .split('{{ phone }}').join(replaceWith)
        .split('{{ phone}}').join(replaceWith)
        .split('{{phone }}').join(replaceWith);
    }
    return text;
  }

  private static searchForMessageText(type: string, targetObj: any) {
    const value: any = [];

    const recursiveFn = (obj: any) => {
      for (const key in obj) {
        if (type === key) {
          value.push(obj[key]);
        }
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          recursiveFn(obj[key]);
        }
      }
    }

    recursiveFn(targetObj);

    return value.join('');
  }

  private static flattenVariables(variables: any, parsedVariables: any) {
    for (const key in variables) {
      if (typeof variables[key] === 'string' || typeof variables[key] === 'number') {
        parsedVariables[key] = variables[key].toString();
      }
      if (typeof variables[key] === 'object' && !Array.isArray(variables[key])) {
        this.flattenVariables(variables[key], parsedVariables);
      }
    }
  }
}
