import { connect } from 'react-redux';
import ErrorPanel from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './interfaces';
import { WidgetActionsNS } from 'app/actions/widget';
import { ThunkDispatch } from 'redux-thunk';
import { MessageService } from 'app/services/message/message.service';

const NS = 'ErrorPanelContainer';

const mapStateToProps = ({setup, widget}: IRootState): IStateFromProps => {

  return {
    message: setup.appErrorMessage || MessageService.get('noAccount'),
    bookingError: widget.bookingError
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {

  }
};

const ErrorPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPanel as any);

export default ErrorPanelContainer;
