import { connect } from 'react-redux';
import LoaderOverlay from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { loadStatus } from 'app/types/common.types';

const NS = 'LoaderOverlayContainer';

const mapStateToProps = ({widget, setup}: IRootState): IStateFromProps => {
  
  return {
    // wrapperStyle: widget.wrapperStyle,
    theme: widget.theme,
    message: setup.appLoadStatus === loadStatus.loading ? setup.appLoadMessage : 'Loaded'
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    
  }
};

const LoaderOverlayContainer = connect(
  mapStateToProps, 
  mapDispatchToProps
)(LoaderOverlay as any);

export default (LoaderOverlayContainer);