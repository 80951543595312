// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".languagePickerContainer--PMJOZ{position:relative;z-index:1}.languagePickerAnchor--bEK56{position:absolute;right:10px;top:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languagePickerContainer": "languagePickerContainer--PMJOZ",
	"languagePickerAnchor": "languagePickerAnchor--bEK56"
};
export default ___CSS_LOADER_EXPORT___;
