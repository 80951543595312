
export enum GoogleEventCategory {
  URL = 'URL',
  Payment = 'Payment',
  UserInput = 'User input',
  Booking = 'Booking',
  ManageBooking = 'Manage Booking',
  Timeout = 'Timeout'
}

export enum AnalyticsReporter {
  GTM = 'googleTagManager',
  GA = 'googleAnalytics',
  Facebook = 'facebook',
  BUBBLE = 'bubble'
}

export enum GoogleTagManagerEmbed {
  TYPE = 'NBIWidget2GoogleAnalytics'
}
