export default {
  maximumPeopleMessage: `Please contact us for bookings larger than {{maxPeoplePerBooking}} on <a href='tel:{{phone}}'>{{phone}}</a>`,
  minPaxPerBookingMessage: `Minimum booking size is {{minPaxPerBooking}}`,
  maxPeople: `Please contact us for bookings larger than {{maxPeoplePerBooking}} on <a href='tel:{{phone}}'>{{phone}}</a>`,
  noTablesAvailableMessage: `Tables are very limited at this time. Please call us on <a href='tel:{{phone}}'>{{phone}}</a>`,
  noTables: `Tables are very limited at this time. Please call us on <a href='tel:{{phone}}'>{{phone}}</a>`,
  timeNoLongerAvailable: `Tables are very limited at this time. Please call us on <a href='tel:{{phone}}'>{{phone}}</a>`,
  timeNoLongerAvailableMessage: `Tables are very limited at this time. Please call us on <a href='tel:{{phone}}'>{{phone}}</a>`,
  serverError: `Please call on <a href='tel:{{phone}}'>{{phone}}</a>`,
  dayClosedMessage: 'Unfortunately we are closed on this day, but would love to see you another day.',
  venueClosed: 'Unfortunately we are closed on this day, but would love to see you another day.',
  inactive: `Sorry, online bookings are unavailable. Please contact {{name}} on <a href='tel:{{phone}}'>{{phone}}</a>.`,
  timeout: 'Sorry your session has expired.',
  clientError: 'Sorry your booking has failed.',
  noAccount: 'We are sorry, online bookings are not available.',
  tooFarInAdvanceMessage: `Sorry, we don't take online bookings this far in advance. Please call us on <a href='tel:{{phone}}'>{{phone}}</a>.`,
  blockoutMessage: 'Sorry, we aren\'t taking bookings for date you\'ve requested',
  finalWidgetScreenMessage: 'Thanks for your booking, we can\'t wait to see you. We\'ve sent you an email with the details of this booking.',
  onlineBookingsOffMessage: 'Sorry, online bookings are unavailable.',
  timeNotAvailableMessage: 'Unavailable' 
};
