import { ToastType } from 'shared-types/index';

export interface ILoginActions {
  handleLogout: (callback: () => void) => any;
}

export interface IPouchActions {
  handlePouchSyncedDoctype: (
    type: PouchDocType,
    callback: (evt: any, payload?: any) => void
  ) => any;
  setup: (rootScope: any) => any;
  updatePouchSyncedDoctype: (type: PouchDocType, docIds?: any) => void;
}

export interface IManagerActions {
  managerChange: () => void;
}

export enum VenueActionNames {
  VENUE_CHANGE = 'venue-change', // no params to callback
  VENUE_LOADED = 'venue-loaded', // no params to callback
  RELOAD_NEEDED = 'reload-needed',
}

export enum ToastActionNames {
  SHOW_TOAST = 'SHOW_TOAST',
  SHOW_TOAST_FULL_OPTIONS = 'SHOW_TOAST_FULL_OPTIONS',
  CLEAR_TOAST = 'CLEAR_TOAST',
}

export interface IToastActionPayload extends IToastDialogProps {}

export interface IToastAction {
  showToast: (msg: string, type: ToastType) => void;
  handleShowToast: (
    callback: (msg: string, type: ToastType, hideDismissBtn: boolean, isImportant: boolean) => void
  ) => void;
  handleShowToastFullOptions: (callback: (props: IToastActionPayload) => void) => void;
  handleClearToast: (callback: () => void) => void;
  clearToast: () => void;
  showToastFullOptions: (props: IToastActionPayload) => void;
}

export enum PouchDocType {
  tag = 'tag',
  receiveChange = 'receiveChange',
  booking = 'booking',
  event = 'event',
  layout = 'layout',
  status = 'status',
  note = 'note',
  blockout = 'blockout',
  schedule = 'schedule',
  section = 'section',
  table = 'table',
  sectionGroup = 'sectionGroup',
  serviceGroup = 'serviceGroup',
}

export interface IVenueActions {
  venueAction: (name: VenueActionNames, payload?: any) => void;
  handleVenueAction: (name: VenueActionNames, callback: () => void) => void;
}

export interface INetworkConnectionActions {
  statusChange: () => void;
  handleStatusChange: (cb: () => void, actionName?: string) => void;
}

/**
 * Properties passed directly into the react component. If the container is responsible for fetching async data, via a
 * service, these may differ from IContainerProps.
 */
export interface IToastDialogProps {
  msg?: string;
  type: ToastType;
  hideDismissBtn?: boolean;
  isImportant: boolean; // will display top center instead of bottom right

  // optional extra button 1
  customButton1Text?: string;
  handleCustomButton1?: () => void;

  // optional extra button 2
  customButton2Text?: string;
  handleCustomButton2?: () => void;

  // optional extra button 3
  customButton3Text?: string;
  handleCustomButton3?: () => void;
}
