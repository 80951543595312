import React from 'react';
import style from './style.module.scss';
import {Typography} from '@material-ui/core';
import {IDispatchFromProps, IStateFromProps} from './interfaces';
import {renderIf} from "app/services/utils/utils.service";
import {noCase} from "change-case";
import {IntlService} from "app/services/intl/intlService";

// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');

const NS = 'ErrorPanel';


export default function ErrorPanel({message, bookingError}: IStateFromProps & IDispatchFromProps) {
  return (
    <div className={style.root} data-testid="container">
      {renderIf(bookingError, (
          <Typography variant="subtitle1" data-testid="error-message">
            Online Bookings Unavailable
          </Typography>
        ),
      )}

      <Typography component="div" variant="body1" className={style.body} data-testid="markdown">
        <ReactMarkdown source={message} escapeHtml={false}/>
      </Typography>
    </div>
  )
}
