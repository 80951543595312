import { connect } from 'react-redux';
import Setup from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './interfaces';
import { ThunkDispatch } from 'redux-thunk';
import { loadStatus } from 'app/types/common.types';
import { themeTypes } from 'app/models';

const NS = 'Setup';

const mapStateToProps = ({setup, widget}: IRootState): IStateFromProps => {
  // using `appSettings.theme` because `props.theme` has not initialized yet
  const isDark = widget.appSettings.theme === themeTypes.dark || widget.appSettings.theme === themeTypes.outlinedDark;
  return {
    message: setup.appLoadStatus === loadStatus.loading ? setup.appLoadMessage : 'Loaded',
    isDark
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {}
};

const SetupContainer = connect(
  mapStateToProps, 
  mapDispatchToProps
)(Setup as any);

export default SetupContainer;