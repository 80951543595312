import { Box, Button, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LanguageIcon from "@material-ui/icons/Language";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SupportedLanguage, getSupportedLanguages } from "app/constants/supportedLanguages";
import style from "./style.module.scss";

const LANGUAGE_LABEL: {
  [key in SupportedLanguage]: { label: string };
} = {
  // Canadian English - $1,234.56 - 1:45 pm.
  "en-CA": { label: "English" },
  // Australian English - $1,234.56 - 1:45 pm.
  "en-AU": { label: "English" },
  // New Zealand English - $1,234.56 - 1:45 pm.
  "en-NZ": { label: "English" },
  // Canadian French - 1 234,56 $ - 13:45.
  "fr-CA": { label: "Français" },
};

const LANGUAGES = (getSupportedLanguages()).map((lng) => ({
  lng,
  ...LANGUAGE_LABEL[lng],
}));

export default function LanguagePicker() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  /** Minimum width of popover to ensure it's not shorter than the anchor. */
  const widthRef = useRef(120);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    widthRef.current = e.currentTarget.getBoundingClientRect().width;
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /** Calblack when a language is selected. Pass on language change to i18n. */
  const onLang = (lng: SupportedLanguage) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  /** Count the number of distinct languages, so "en-AU" and "en-NZ" count as only one. */
  const distinctLanguages = new Set(getSupportedLanguages().map((lng) => lng.split('-')[0])).size;

  return distinctLanguages <= 1 ? (
    <></>
  ) : (
    <Box className={style.languagePickerContainer}>
      <Box className={style.languagePickerAnchor}>
        <Button
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpen}
          startIcon={<LanguageIcon />}
          endIcon={<ArrowDropDownIcon />}
          variant="outlined"
          style={{ textTransform: "none", fontSize: "1.4rem" }}
        >
          {LANGUAGES.find((l) => l.lng === lang)?.label || "Language"}
        </Button>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={open}
          onClose={handleClose}
          PaperProps={{ style: { minWidth: widthRef.current, marginLeft: 6 } }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {LANGUAGES.map((l) => (
            <MenuItem key={l.lng} value={l.lng} onClick={() => onLang(l.lng)}>
              {l.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
}
