import React from 'react';
import style from './style.module.scss';
import { CircularProgress, Typography } from '@material-ui/core';
import { IStateFromProps } from './interfaces';
import { renderIf } from 'app/services/utils/utils.service';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';


export default function Setup({message, isDark}: IStateFromProps) {
  const { t } = useTranslation("loader")
  return (
    <div>
      {renderIf(message, () => (
        <Typography variant="subtitle1" align="center" className={classNames({
          [style.textIsDark]: isDark
        })}>
          {t(message)}
        </Typography>
      ))}
      <div className={style.loaderWrap}>
        <CircularProgress className={classNames({
          [style.loaderIsDark]: !isDark,
          [style.loaderIsLight]: isDark
        })} />
      </div>
    </div>
  )
}