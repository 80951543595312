import { connect } from 'react-redux';
import ProgressStepper from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps, IProgressStep } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { ROUTE_NAMES } from 'app/services/route/route.types';

const NS = 'ProgressStepperContainer';

/**
 * NOTE:
 * This component gets used with container (steps) and without (payments)
 */

const mapStateToProps = (state: IRootState): IStateFromProps => {

  const nav = state.navigation;
  const currentRouteName: string = nav.stepRoutes[nav.currentStepIndex];
  
  const steps: IProgressStep[] = [{
    label: 'Venues' as const,
    routeName: ROUTE_NAMES.VENUES
  }, {
    label: 'Booking' as const,
    routeName: ROUTE_NAMES.SITTING
  }, {
    label: 'Your Details' as const,
    routeName: ROUTE_NAMES.CUSTOMER_DETAILS
  }, {
    label: 'Summary' as const,
    routeName: ROUTE_NAMES.SUMMARY
  }].filter(step => nav.stepRoutes.indexOf(step.routeName) !== -1);


  let activeStep: number = steps.findIndex(step => step.routeName === currentRouteName);
  if (activeStep === -1) {
    activeStep = 0;
  }
  
  return {
    theme: state.widget.theme,
    activeStep,
    steps,
    wrapperStyle: state.widget.wrapperStyle
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    // handleSomeClick: () => {
      // dispatch(WidgetActionsNS.xxx());
    // }
  }
};

const ProgressStepperContainer = connect(
  mapStateToProps, 
  mapDispatchToProps
)(ProgressStepper as any);

export default ProgressStepperContainer;